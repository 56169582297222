import React, {useContext, useEffect, useState} from "react";
import '../../styles/components/Modal/Modal.scss';
import {Modal} from "../index";
import {getConnectionsModalData} from "../../api/ApiClient";
import UserData from "../../store/User/UserData";
import {Loader} from "../Loader/Loader";
import {abbreviateNumber, formatTime} from "../../util/format";
import Table from "../Table/Table";
import NotFound from "../../navigation/NotFound";
import TableHeader from "../Table/TableHeader";
import Row from "../Table/Row";
import '../../styles/pages/Connections/ConnectionsModal.scss';
import PaginationControls from "../Table/PaginationControls";
import {firewallModalRadios, tableHeaderConfig} from "../../pages/Connections/config/connectionsMap";
import {captureSentryError} from "../../util/sentry";
import Tooltip from "../Tooltip/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioSelection from "../../pages/Onboarding/Cloud/components/RadioSelection";
import {firewallTooltip} from "../../pages/Connections/config/firewalltooltip";
import { downloadFile } from "../../util/export";
import LoaderButton from "../Button/LoaderButton";

const ConnectionsModal = ({close, title}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({results: [], total: 0})
  const [userData] = useContext(UserData);
  const [sortFilters, setSortFilters] = useState([])
  const [page, setPage] = useState(1)
  const [fetchError, setFetchError] = useState(false)
  const [modalParams, setModalParams] = useState({page: 1})
  const [radioSelected, setRadioSelected] = useState('onboarding');
  const [exportLoading, setExportLoading] = useState(false);

  const handleRadioSelection = (_, name) => setRadioSelected(name)

  const handleChangePage = currPage => {
    setPage(currPage)
    setModalParams({...modalParams, page: currPage})
  };

  const getConnections = async (isOnboarding, isExport) => {
    setFetchError(false);
    isExport? setExportLoading(true) : setLoading(true);
    try {
      isOnboarding ? delete modalParams.size : modalParams.size = 50
      isOnboarding ? modalParams.onboarding_only = "true" : delete modalParams.onboarding_only
      const params = {type: 'firewall', ...modalParams};
      const response = await getConnectionsModalData(userData?.tenant, params, isExport);
      if(isExport) downloadFile(response);
      else {
        let filteredResponse = radioSelected === "non-onboarding" ? response?.results?.firewall
          : response?.results?.firewall.filter(item => item.label !== "Citrix NetScaler")
        let newTotal = radioSelected === "non-onboarding" ? response?.total
          : filteredResponse?.length
        setData({results: filteredResponse, total: newTotal})
      }
    } catch (e) {
      captureSentryError(e, userData, `getConnectionsModalData API ${isExport && "- export - "}in ConnectionsModal.js`);
      setFetchError(true);
    } finally {
      isExport? setExportLoading(false) : setLoading(false);
    }
  };

  useEffect(() => {
    (userData?.tenant && !loading) && getConnections(radioSelected === "onboarding")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, modalParams, radioSelected]);

  return (
    <Modal close={close} className={"connections-list-modal"}>
      <p className={"modal-title connections-list input-label"}>{title}</p>
      {!loading &&
      <div className={"page-container flex" + (radioSelected === 'onboarding' ? ' onboarding' : '')}>
        {radioSelected === 'onboarding' ? <div className={"total-results"}>
            <span className={"total"}>{data?.total}</span> {data?.total === 1 ? 'Result' : 'Results'}
        </div>
          : <PaginationControls
            displayData={data}
            page={page}
            setPage={handleChangePage}
            toggleShowDropdown={() => {}}
            setParams={setModalParams}
            params={modalParams}
            showSortDropdown={false}
          />}
        <div className="radio-container">
          <RadioSelection
            options={firewallModalRadios}
            handleChange={handleRadioSelection}
            selected={radioSelected}
            className="firewall-radios flex"
            icon={<RadioButtonUncheckedIcon/>}
            checkedIcon={<RadioButtonCheckedIcon className={"checked-box-icon"}/>}
          />
        </div>
        <div className="export-btn">
          <LoaderButton
            text="Export"
            loading={exportLoading}
            onClick={() => getConnections(radioSelected === "onboarding", true)}
          />
        </div>
      </div>}
      {!loading ?
        fetchError ? <NotFound isError dataError className="panel modal"/>
          :
          <Table page={"connections-list-table"}>
            <TableHeader
              mainRow={tableHeaderConfig}
              setSortFilters={setSortFilters}
              sortFilters={sortFilters}
              setParams={setModalParams}
              params={modalParams}
              tooltipIcon={field => field === 'STATUS' && (
                <div className="modal-tooltip">
                  <Tooltip content={firewallTooltip} direction="bottom" disableTimeout>
                    <InfoOutlinedIcon className="info-icon" />
                  </Tooltip>
                </div>
              )}
            />
            {[...data?.results].map((item, i) => (
              <Row className={`list-row`} key={i} page="service-status">
                <td className="list-item ip-address">{item.ip_address}</td>
                <td className="list-item status"><span className={`circle ${item.status}`}/></td>
                <td className="list-item label">{item.label || "Unknown"}</td>
                <td className="list-item last-seen">{formatTime(item.last_seen)}</td>
                <td className="list-item total-events">{abbreviateNumber(item.total_events)}</td>
                <td className="list-item comment">{item.comment}</td>
              </Row>
            ))}
          </Table> : <Loader/>}
    </Modal>
  );
};

export default ConnectionsModal;
